<template functional>
  <div class="source">
    <h2 class="source__title text-center">
      Официальные источники данных
    </h2>
    <div class="source__list">
      <div class="centred-wrapper">
        <div class="source__wrapper">
          <div class="source__list__item source-item">
            <img
              v-lazy-load
              src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
              :data-src="require('~/assets/img/source/gibdd.png')"
              width="80"
              height="80"
              alt="База данных ГИБДД"
              class="source-item__logo"
              loading="lazy"
              style="margin-bottom: 27px"
            >
            <div class="source-item__title">
              База данных<br>ГИБДД
            </div>
          </div>
        </div>
        <div class="source__wrapper">
          <div class="source__list__item source-item">
            <img
              v-lazy-load
              src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
              :data-src="require('~/assets/img/source/fkr.png')"
              width="80"
              height="80"
              alt="Федеральное Казначейство России"
              class="source-item__logo"
              loading="lazy"
              style="margin-bottom: 16px"
            >
            <div class="source-item__title">
              Федеральное<br>Казначейство<br>России
            </div>
          </div>
        </div>
        <div class="source__wrapper">
          <div class="source__list__item source-item">
            <img
              v-lazy-load
              src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
              :data-src="require('~/assets/img/source/fssp.png')"
              width="80"
              height="80"
              alt="Банк данных ФССП"
              class="source-item__logo"
              loading="lazy"
              style="margin-bottom: 23px"
            >
            <div class="source-item__title">
              Банк данных<br>ФССП
            </div>
          </div>
        </div>
        <div class="source__wrapper">
          <div class="source__list__item source-item">
            <img
              v-lazy-load
              src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
              :data-src="require('~/assets/img/source/madi.png')"
              width="80"
              height="80"
              alt="МАДИ, АМПП"
              class="source-item__logo"
              loading="lazy"
              style="margin-bottom: 30px"
            >
            <div class="source-item__title">
              МАДИ, АМПП
            </div>
          </div>
        </div>
        <div class="source__wrapper">
          <div class="source__list__item source-item">
            <img
              v-lazy-load
              src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
              :data-src="require('~/assets/img/source/mugadn.png')"
              width="80"
              height="80"
              alt="МУГАДН"
              class="source-item__logo"
              loading="lazy"
              style="margin-bottom: 30px"
            >
            <div id="mobile-banner" class="source-item__title">
              МУГАДН
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OfficialSource'
}
</script>

<style lang="scss" scoped>

.source__title{
  margin-bottom: 16px;
  color: #626282;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400 !important;

  @include bp-min($mq-tablet-min) {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 32px;
  }
}
.source__list{
  @media (max-width:768px) {
    justify-content: center;
  }
}

.centred-wrapper {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 141px 141px;
  width: 100%;
  justify-content: center;
  align-content: center;

  @media (min-width: 350px) {
    grid-template-columns: 160px 160px;
  }

  @include bp-min($mq-mobile-small) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include bp-min($mq-tablet-small) {
    grid-gap: 32px;
    grid-template-columns: repeat(5, 1fr);
  }
}

.source {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__wrapper {
    width: 142px;
    height: 200px;
    background: #ffffff;
    box-shadow: 0 100px 80px rgba(0, 97, 217, 0.07),
    0 50.625px 34.875px rgba(0, 97, 217, 0.04725),
    0 20px 13px rgba(0, 97, 217, 0.035),
    0 4.375px 4.625px rgba(0, 97, 217, 0.02275);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 30px;

    @media (min-width: 350px) {
      width: 160px;
    }

    @include bp-min($mq-mobile-small) {
      width: 100%;
    }

      &:last-child {
      grid-column: 1/3;
      margin: 0 auto;

      @include bp-min($mq-mobile-small) {
        grid-column: auto;

        br {
          display: none;
        }
      }
    }
  }

  &__list {
    &__item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.source-item {
  &__logo {
    flex: 0 0 auto;
  }

  &__title {
    font-size: 14px;
    line-height: 115%;
    text-align: center;
    color: #010B23;
  }
}
</style>
