export const STUB_REVIEWS = [
  {
    id: 153,
    full_name: 'Дима',
    email: 'dima777@gmail.com',
    content: 'Отличный сервис, во-время присылает уведомления и оплата проходит день в день. Молодцы!',
    admin_response: null,
    rating: 5,
    is_published: 1,
    created_at: '2021-09-05 00:00:00',
    updated_at: '2021-09-05 20:11:32',
    client_app_id: 'paygibdd_web_app'
  },
  {
    id: 19,
    full_name: 'Ленар',
    email: 'lenartimerkhanoff@yandex.ru',
    content: 'Сайт понятный и простой, разобрался за секунды. Проверка мгновенная',
    admin_response: '',
    rating: 4,
    is_published: 1,
    created_at: '2021-07-15 00:00:00',
    updated_at: '2022-09-01 15:51:23',
    client_app_id: 'paygibdd_web_app'
  },
  {
    id: 14,
    full_name: 'Михаил',
    email: 'i@ma.ru',
    content: 'Пока что самый удобный сервис оплаты штрафов ГИБДД из тех, которыми я пользовался. Сервис видит все штрафы, в отличие от аналогичных сервисов банков, можно оплатить все сразу. Есть фото нарушения. Квитанция пришла на почту сразу после оплаты, сам платеж прошел очень быстро, даже не ожидал.',
    admin_response: null,
    rating: 5,
    is_published: 1,
    created_at: '2021-04-16 00:00:00',
    updated_at: '2021-07-14 18:23:55',
    client_app_id: 'paygibdd_web_app'
  }
]
