<template functional>
  <div class="source mt-5">
    <h2 class="source__title w-100 text-center">
      Сервис для юридических лиц
    </h2>
    <div class="source__description text-center">
      Наш сервис поможет вам систематизировать и существенно облегчить процесс оплаты штрафов ГИБДД вашей организации.
    </div>
    <img src="@/assets/img/arrow.svg" class="arrow" alt="arrow">
    <div class="source__logos">
      <img
        alt="excel"
        height="160"
        loading="lazy"
        src="@/assets/img/excel-img.svg"
        width="154"
      >
      <img
        alt="list"
        class="source__logos-list"
        height="160"
        loading="lazy"
        src="@/assets/img/list-img.svg"
        width="135"
      >
      <img
        alt="rubl"
        height="160"
        loading="lazy"
        src="@/assets/img/rubl-img.svg"
        width="128"
      >
    </div>
    <div class="source__list">
      <img src="@/assets/img/arrow-small.svg" class="arrow-small" alt="arrow-small">
      <div class="source__block">
        <span>1</span>
        <div class="source__wrapper">
          <div class="source__list__item source-item">
            <div class="source-item__title">
              Заполните реестр ваших автомобилей, водителей или юридических лиц для проверки штрафов в формате Excel и загрузите файл в систему
            </div>
          </div>
        </div>
      </div>
      <div class="source__block">
        <span>2</span>
        <div class="source__wrapper">
          <div class="source__list__item source-item">
            <div class="source-item__title">
              Получите список оплаченных и неоплаченных штрафов с подробными характеристиками, адресами и датами, выберите штрафы для оплаты
            </div>
          </div>
        </div>
      </div>
      <div class="source__block">
        <span>3</span>
        <div class="source__wrapper">
          <div class="source__list__item source-item">
            <div class="source-item__title">
              Скачайте сводное платежное поручение в формате 1С, загрузите его в Ваш банк клиент и оплатите все штрафы в один-клик без комиссии
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="more-link">
      <a href="https://simplepayments24.ru/b2b" target="_blank">
        <div class="d-flex align-items-center">
          <p class="mr-3 mb-0 more-info">
            узнать подробнее
          </p>
          <svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 22L12 12L2 2" stroke="#0061D9" stroke-width="3" stroke-linecap="round" />
          </svg>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServicesForOfficial'
}
</script>

<style lang="scss" scoped>

.more-info{
  width: 100%;
  font-size: 12px;
  font-weight:500;
}

.arrow {
  position: relative;
  top: 5rem;
  width: 100%;
  @media (max-width: 1000px) {
    display: none;
  }
  &-small {
    display: none;
    @media (max-width: 1000px) {
      display: block;
      position: absolute;
      left: 0;
      height: 100%;
      top: .5rem;
      @include bp-max($mq-mobile-medium) {
        top: .8rem;
        left: -3px;
      }
    }
  }
}

.source__title{
  margin-top: 50px;
  margin-bottom: 32px;
  font-size: 22px !important;
  line-height: 28px !important;
  color: #626282;
  font-weight: 400 !important;
  @media (max-width: 768px) {
    align-self: flex-start;
  }
}
.more-link{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;
  position: relative;
  margin-top: 34px;

  @media(min-width: 768px) and (max-width: 992px) {
    margin-top: 25px;
  }

  @media (max-width:768px) {
    a{
      display: flex;
      justify-content: center;
    }
    .more-info{
      width: auto;
    }
  }
  p {
    font-size: 12px;
    line-height: 133%;
    letter-spacing: 0.02em;
    color:#010B23;
    font-weight:500;
    text-transform: uppercase;
  }
}

.source {
  position: relative;
  margin-bottom: 20px;

  &__block {
    display:flex;
    gap: .5rem;
    @media (max-width: 1000px) {
      width: 100%;
    }
    @include bp-max($mq-mobile-medium) {
      margin-left: 1rem;
    }
    & > span {
      position: relative;
      top: -.5rem;
      font-size: 36px;
      color: #0061D9;

      @include bp-min($mq-mobile-medium) {
        top: -15px;
      }
    }

    &:nth-child(3) {
      span {
        top: -20px;
        @include bp-max($mq-mobile-medium) {
          top: -15px;
        }
      }
    }

    &:last-child {
      @media (min-width: 1001px) {
        margin-left: 10px;
      }
    }
  }

  &__logos {
    position: absolute;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    width: 100%;
    left: -55px;
    grid-gap: 110px;
    @media (max-width: 1000px) {
      display: none;
    }
    &-list {
      margin-right: 1rem;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 16px;
    padding: 0 80px;
    line-height: 150%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #010B23;
    margin-bottom: 48px;
    @media (max-width: 1000px) {
      padding: 0;
      text-align: left !important;
    }
  }

  &__wrapper{
    width: 195px;
    height: 190px;
    background: #FFFFFF;
    box-shadow: 0 80px 80px rgba(0, 97, 217, 0.07), 0 50.625px 34.875px rgba(0, 97, 217, 0.04725), 0 20px 13px rgba(0, 97, 217, 0.035), 0 4.375px 4.625px rgba(0, 97, 217, 0.02275);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;

    &:not(:last-child){
      margin-right: 12px;
    }
    @media (max-width: 1000px) {
      width: 100%;
      height: auto;
    }
    @media (max-width:768px) {
      margin-right: 6px !important;
    }
  }

  &__list {
    position: relative;
    display: flex;
    margin-top: 95px;
    justify-content: flex-start;
    gap: 116px;
    flex-wrap: nowrap;
    margin-left: 2px;
    @media (max-width: 1000px) {
      flex-wrap: wrap;
      flex-direction: column;
      margin-left: 0;
      padding-left: 2rem;
      margin-top: 0;
      gap: 20px;
    }

    @include bp-max($mq-mobile-medium){
      padding-left: 1rem;
    }

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      @media (max-width:768px) {
        align-items: flex-end !important;
        width: 100%;
        margin-top: -5px;
      }
    }
  }
}

.source-item {
  display: flex;
  align-items: center;

  &__logo {
    flex: 0 0 auto;
    margin-bottom:30px
  }

  &__title{
    font-size: 14px;
    text-align: start;
    color: #010B23;
    //padding: 16px;
    line-height: 142%;
    font-weight: 400;
    letter-spacing: -0.01em;
    @media (max-width: 1000px) {
      text-align: left !important;
    }
  }
}

.source-desc{
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #010B23;
}

.container{
  background: #FFFFFF;
  box-shadow: 0px 100px 80px rgba(0, 97, 217, 0.07), 0px 50.625px 34.875px rgba(0, 97, 217, 0.04725), 0px 20px 13px rgba(0, 97, 217, 0.035), 0px 4.375px 4.625px rgba(0, 97, 217, 0.02275);
  border-radius: 8px;
  padding: 40px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .source__title{
    margin-top:0;
    text-align: center;
  }
  .new-button{
    margin-top: 32px;
    width: 261px;
  }
  @media (max-width:768px) {
    padding: 20px !important;
    align-items: flex-start;
    .source__title{
      margin-top:0;
      font-size: 20px;
      text-align: left;
    }
    .source-desc{
      text-align: left;
    }
    .new-button{
      margin-top: 32px;
      width: 100%;
    }
  }
}

</style>
