
import { Component, Vue } from 'nuxt-property-decorator'

@Component({
  name: 'InfoAccordion',
  components: {
    FullScreenImage: () => import('~/components/FullScreenImage.vue')
  }
})
export default class extends Vue {
  showFullScreen: boolean = false
  checkArray: boolean[] = [true, true, true, true, true, true, true]

  setCheck (index) {
    this.checkArray = this.checkArray.map((_item, ind) => index !== ind)
  }
}
